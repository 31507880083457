<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="35%"
      v-on="$listeners"
      custom-class="submitted-dialog"
      :close-on-click-modal="false"
      :show-close="false">
      <div>
        <div class="tip">Your contract has been submitted!</div>
        <div class="tip">Date and time created: {{ responseData.createTime }} HRS</div>
        <div class="btn">
          <el-button
            type="primary"
            round
            @click="downloadPdf">
            Download as PDF
          </el-button>
          <el-button
            type="danger"
            round
            @click="dialogVisible = false">
            Done
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>

</template>
<script>

export default {
  name: 'Submitted',
  props: {
    responseData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      dialogVisible: false
    }
  },
  mounted () {
    this.dialogVisible = true
  },
  methods: {
    downloadPdf () {
    }
  }
}
</script>

<style lang="scss" scoped>
.submitted-dialog {
  .tip {
    margin-bottom: 10px;
    text-align: center;
    font-size: 18px;
  }

  .btn {
    @include flex-center-column;
    margin-top: 40px;

    button {
      width: 200px;
      margin: 0 0 20px 0;
    }
  }
}
</style>
